<script>
export default {
  methods: {
    getTotal(index) {
      return this.chartData.datasets.reduce((sum, v) => sum + Math.max(0, v.data[index]), 0)
    },

    getTooltipTitleWithTotal(items, params) {
      const { precision, unit, title, suffix } = {
        precision: 0,
        unit: null,
        title: null,
        suffix: true,
        ...params,
      }
      const index = items[0].dataIndex
      const totalValue = this.formatNumber(this.getTotal(index), precision)

      return `${title}: ${totalValue}${unit ? ` ${unit} ` : ''}${suffix ? ' (100%)' : ''}`
    },

    getTooltipTitleWithIdentifier(items) {
      return this.chartData?.[items[0].dataIndex]?.identifier || ''
    },

    getTooltipLabelWithPercentage(item, params) {
      const { label, unit, getTotalFn } = { label: null, unit: null, getTotalFn: undefined, ...params }
      const datasetLabel = item.dataset.label
      const totalValue = getTotalFn ? getTotalFn(item.dataIndex) : this.getTotal(item.dataIndex)
      const yValue = this.formatNumber(item.parsed.y, 1)
      const percentage = this.formatNumber((item.parsed.y / totalValue) * 100, 0)

      if (item.parsed.y > 0) {
        return `${label || datasetLabel}: ${yValue}${unit ? ` ${unit} ` : ''} (${percentage}%)`
      } else {
        return `${label || datasetLabel}: -`
      }
    },

    getTooltipLabelWithReductionString(item, params) {
      let reductionString = ''

      if (!this.targets || item.datasetIndex > 0) {
        const value = ((item.parsed.y - item.dataset.data[0]) / item.dataset.data[0]) * 100
        // Empty string is infinite
        reductionString = isFinite(value) ? ` (${this.formatNumber(value, 0)}%)` : ''
      }
      return this.getTooltipLabel(item, { ...params, unit: `${params.unit || ''}${reductionString}` })
    },

    getTooltipLabel(item, params) {
      const { precision, unit, label, axis } = { precision: 0, unit: null, label: null, axis: 'y', ...params }
      const value = item.parsed?.[axis] !== undefined ? item.parsed[axis] : item.parsed

      return `${label || item.dataset.label || item.label}: ${this.formatNumber(value, precision) || '-'}${
        unit ? ` ${unit}` : ''
      }`
    },

    getTooltipBeforeBodyWithDescription(items) {
      return `${this.chartData[items[0].dataIndex]?.description || ''}`
    },

    onChartClick(event, items) {
      if (items.length !== 0) {
        this.$emit('click', this.chartData[items[0].index])
      }
    },
  },
}
</script>

<i18n>
  {
    "de": {
      "yLabel": "Emissionen (kg CO₂e/m²)",
      "emissionsTooltip": "Emissionen",
      "emissionsUnitsTooltip": "kg CO₂e/m²",
      "benchmarkTitle": "REIDA Benchmark {year}",
      "benchmarkLabel": "CO2: {value} {unit}\n(Bandbreite zwischen {min} und {max} {unit})"
    }
  }
  </i18n>

<template>
  <div>
    <BubbleChart :chart-data="processedChartData" :options="options" class="bubble-chart" />
    <br />
    <BubbleReportLegend :benchmark="benchmark" :palette="palette" />
  </div>
</template>

<script>
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import BubbleChart from '@/components/shared/charts/BubbleChart.vue'
import BubbleReportLegend from '@/components/report/BubbleReportLegend.vue'
import seedrandom from 'seedrandom'

export default {
  mixins: [ChartOptionsMixin],

  components: {
    BubbleChart,
    BubbleReportLegend,
  },

  props: {
    show: {
      type: Boolean,
      default: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
    benchmark: {
      type: Object,
      required: false,
    },
  },

  computed: {
    palette() {
      return {
        data: {
          background: 'hsla(120, 87.5%, 23.33%, 60%)',
          hoverBackground: 'hsla(20, 92%, 44%, 25%)',
          border: 'hsla(0, 0%, 100%, 53.3%)',
          hoverBorder: 'hsla(20, 92%, 44%, 70%)',
        },
        benchmark: {
          point: {
            background: 'hsl(39, 100%, 50%)',
            border: 'hsl(0, 0%, 100%)',
          },
          range: {
            background: 'hsla(0, 0%, 75%, 18%)',
            border: 'hsla(0, 0%, 75%, 50%)',
          },
          line: {
            border: 'hsl(0, 0%, 75%)',
          },
        },
      }
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.onChartClick,
        layout: {
          padding: {
            left: 100,
            right: 100,
            top: 20,
            bottom: 20,
          },
        },
        scales: {
          xAxis: {
            display: false,
            type: 'linear',
            min: -15,
            max: 15,
          },
          yAxis: {
            beginAtZero: true,
            title: {
              display: true,
              text: this.$t('yLabel'),
            },
          },
        },
        plugins: {
          annotation: {
            annotations: this.benchmark
              ? {
                  range: {
                    type: 'box',
                    xMin: 'start',
                    xMax: 'end',
                    yMin: this.benchmark.co2Min,
                    yMax: this.benchmark.co2Max,
                    borderWidth: 1,
                    borderColor: this.palette.benchmark.range.border,
                    backgroundColor: this.palette.benchmark.range.background,
                  },
                  xLine: {
                    type: 'line',
                    yMin: this.benchmark.co2,
                    yMax: this.benchmark.co2,
                    borderColor: this.palette.benchmark.line.border,
                    borderWidth: 2,
                    borderDash: [4, 3],
                  },
                  benchmarkPoint: {
                    type: 'point',
                    xValue: 'center',
                    yValue: this.benchmark.co2,
                    radius: 6,
                    borderColor: this.palette.benchmark.point.border,
                    borderWidth: 2,
                    backgroundColor: this.palette.benchmark.point.background,
                  },
                }
              : {},
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              title: (items) =>
                this.isBenchmarkItem(items[0])
                  ? this.$t('benchmarkTitle', { year: this.benchmark.description })
                  : this.getTooltipTitleWithIdentifier(items),
              beforeBody: (items) =>
                this.isBenchmarkItem(items[0]) ? null : this.getTooltipBeforeBodyWithDescription(items),
              label: (item) =>
                this.isBenchmarkItem(item)
                  ? this.getTooltipBenchmarkLabel()
                  : this.getTooltipLabel(item, {
                      precision: 1,
                      unit: this.$t('emissionsUnitsTooltip'),
                      label: this.$t('emissionsTooltip'),
                    }),
            },
          },
          legend: {
            display: false,
          },
        },
      }
    },

    processedChartData() {
      // Seeded random number generator
      const rng = seedrandom('seed')
      let datasets = []
      let data = []

      for (let building of this.chartData) {
        data.push({
          r: Math.min(Math.max(((building.area - 500) * (15 - 5)) / (5000 - 500) + 5, 5), 15),
          x: rng() * (-10 - 10) + 10, // Jitter x-axis => Math.random() * (-max - min) + min
          y: building.s12e / building.area,
        })
      }

      datasets.push({
        backgroundColor: this.chartData.map((b) => this.palette.data.background),
        borderColor: this.chartData.map((b) => this.palette.data.border),
        borderWidth: 2,
        hoverBackgroundColor: this.palette.data.hoverBackground,
        hoverBorderColor: this.palette.data.hoverBorder,
        hoverBorderWidth: 2,
        data: data,
      })

      const benchmark = this.benchmark
      if (benchmark) {
        datasets[0].data.push({
          x: 0,
          y: benchmark.co2,
          r: 6,
          benchmark: true,
        })
        datasets[0].backgroundColor.push('transparent')
        datasets[0].borderColor.push('transparent')
      }

      return {
        datasets: datasets,
      }
    },
  },

  methods: {
    isBenchmarkItem(item) {
      return Boolean(item.dataset.data[item.dataIndex]?.benchmark)
    },

    getTooltipBenchmarkLabel() {
      const benchmark = this.benchmark
      return this.$t('benchmarkLabel', {
        value: benchmark.co2,
        min: benchmark.co2Min,
        max: benchmark.co2Max,
        unit: this.$t('emissionsUnitsTooltip'),
      })
    },
  },
}
</script>
